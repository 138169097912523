<template>
    <div class="mb-4">
        <div
            class="
                mb-4
                d-flex
                flex-column flex-lg-row
                align-items-lg-center
                justify-content-between
            "
        >
            <h4 class="mb-3 mb-lg-0 font-weight-600 whitespace-nowrap">
                CME Accredited
            </h4>
            <!-- filters -->
            <ul class="modules-filter select-sm d-flex flex-wrap align-items-center">
                <li>
                    <v-select
                        v-model="partner"
                        :options="partnersOptions"
                        label="name"
                        @input="getAllCMEs()"
                    ></v-select>
                </li>
                <li>
                    <v-select
                        v-model="specialty"
                        :options="specialtiesOptions"
                        label="name"
                        @input="getAllCMEs()"
                    ></v-select>
                </li>
                <li class="form-group mb-0">
                    <v-date-picker
                        class="date-picker sm"
                        v-model="time"
                        mode="date"
                        :masks="masks"
                    >
                        <template v-slot="{ inputValue, togglePopover }">
                            <div class="d-flex align-items-center">
                                <button class="btn" @click="togglePopover()">
                                    <svg
                                        width="17"
                                        height="17"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            fill="#959595"
                                            d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                        ></path>
                                    </svg>
                                </button>
                                <input
                                    @focus="togglePopover()"
                                    @blur="togglePopover()"
                                    :value="inputValue"
                                    readonly
                                    :placeholder="`-- ${$t('date')} --`"
                                />

                                <button 
                                @click="time = null"
                                class="btn reset-date-picker">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#959595"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>
                                </button>
                            </div>
                        </template>
                    </v-date-picker>
                </li>
            </ul>
        </div>
        <div class="pb-4">
            <template v-if="loading">
                <div
                v-for="(CME, index) in 3"
                :key="index"
                class="
                    certs-card
                    d-flex
                    align-items-center
                    justify-content-between
                    flex-wrap flex-md-nowrap
                    py-3
                    px-3
                    mb-3
                "
            >
                <div
                    class="
                        d-flex
                        align-items-center
                        w-xs-100 w-50 w-md-auto
                        mb-3 mb-md-0
                    "
                >
                    <div class="badge-result">
                        <b-skeleton class="img-circle" width="5.75rem" height="5.75rem"></b-skeleton>
                    </div>
                    <div class="mx-3">
                        <h5 class="mb-1 font-weight-600 whitespace-nowrap">
                            <b-skeleton width="7rem" height="1rem"></b-skeleton>
                        </h5>
                        <span class="text-gray">
                            <b-skeleton width="5rem" height="0.75rem"></b-skeleton>
                        </span>
                    </div>
                </div>
                <div
                    class="
                        partner
                        text-gray
                        mx-md-2
                        w-50 w-md-auto
                        mb-3 mb-md-0
                        d-flex
                        justify-content-end
                    "
                >
                    <b-skeleton width="7rem" height="1rem"></b-skeleton>
                </div>
                <div class="date text-gray mx-md-2 w-50 w-md-auto">
                    <b-skeleton width="7rem" height="1rem"></b-skeleton>
                </div>
                <b-skeleton style="border-radius: 20px" width="7rem" height="2rem"></b-skeleton>
            </div>
            </template>
            <template v-else-if="!loading && CMELength">
                <div
                    v-for="(CME, index) in CMEs"
                    :key="index"
                    class="
                        certs-card
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap flex-md-nowrap
                        py-3
                        px-3
                        mb-3
                    "
                >
                    <div
                        class="
                            d-flex
                            align-items-center
                            w-xs-100 w-50 w-md-auto
                            mb-3 mb-md-0
                        "
                    >
                        <div class="badge-result">
                            <span class="percent success sm">{{ CME.points || 0 }}%</span>
                            <img
                                width="100"
                                src="@/assets/imgs/result-success.png"
                                alt="badge"
                            />
                        </div>
                        <div class="mx-3">
                            <h5 class="mb-1 font-weight-600 whitespace-nowrap">
                                {{ CME.module }}
                            </h5>
                            <span class="text-gray">
                                {{ CME.specialty }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="
                            partner
                            text-gray
                            mx-md-2
                            w-50 w-md-auto
                            mb-3 mb-md-0
                            d-flex
                            justify-content-end
                        "
                    >
                        {{ CME.partner }}
                    </div>
                    <div class="date text-gray mx-md-2 w-50 w-md-auto">
                        <!-- 21/06/2021 - 10:49AM -->
                        {{ $moment(CME.created_at).format("DD/MM/YYYY") }}
                    </div>
                    <a :href="CME.certificate" :download="`cert_${index}`" class="btn btn-quiz auto px-4">
                        Download ‏
                    </a>
                </div>
            </template>
            <div v-else class="col-md-7 col-lg-6 mx-auto text-center mb-4">
                <p class="no-modules mx-auto py-2">
                    {{ $t('noResults') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import { getCMEs } from "@/API/my-account";
import { getSpecialty, getPartnersForFilter } from "@/API/utilites";

export default {
    name: "CMEAccredited",
    mixins: [pagination],
    data() {
        return {
            masks: {
                input: "YYYY-MM-DD",
            },
            loading: true,
            pagesLinks: [],
            currentPage: 1,
            isFirstPage: true,
            isLastPage: false,
            lastPageID: 1,
            CMEs: [],
            specialtiesOptions: [],
            specialty: {
                id: null,
                name: `-- ${this.$t('specialty')} --`
            },
            partnersOptions: [],
            partner: {
                id: null,
                name: `-- ${this.$t('partners')} --`
            },
            time: null
        }
    },
    watch: {
        time(val) {
            if (val) this.time = this.$moment(new Date(val)).format("YYYY-MM-DD");
            this.getAllCMEs();
        },
    },
    computed: {
        CMELength() {
            return this.CMEs?.length || null
        }
    },
    methods: {
        async initFilters() {

            await getPartnersForFilter().then(res => {
                if (res.status == 200) {
                    // console.log(res.data);
                    const partners = [
                        {
                            id: null,
                            name: `-- ${this.$t('partners')} --`
                        },
                        ...res.data?.data?.partner
                    ];
                                
                    this.partnersOptions = partners;
                }
            });

            await getSpecialty().then(res => {
                if (res.status == 200) {
                    const specialties = [
                        {
                            id: null,
                            name: `-- ${this.$t('specialty')} --`
                        },
                        ...res.data?.data
                    ];

                    this.specialtiesOptions = specialties;
                }
            });

            
        },
        getAllCMEs() {
            this.loading = true;
            this.CMEs = [];
            const filters = {
                page: this.currentPage
            }
            if (this.specialty?.id) filters.speciality = this.specialty.id;
            if (this.partner?.id) filters.partner = this.partner.id;
            if (this.time) filters.date = this.time;

            getCMEs(filters).then(res => {
                this.loading = false;
                if (res.status == 200) {
                        const data = res.data?.data;
                        // console.log(data);

                        if (data?.module) this.CMEs = data.module;

                        if (data?.paginate) {
                            this.pagesLinks = this.pagination(data.paginate, this.currentPage).pagesLinks;
                            this.isFirstPage = this.pagination(data.paginate).isFirstPage;
                            this.isLastPage = this.pagination(data.paginate).isLastPage;
                            this.lastPageID = this.pagination(data.paginate).lastPageID;
                        }
                    }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }
            });
        }
    },
    mounted() {
        this.initFilters();
        this.getAllCMEs();
    }
};
</script>

